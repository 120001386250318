import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type LatLong = {
    latitude: number;
    longitude: number;
};

export interface CoverageAreaState {
    latLong: LatLong;
    radiusInKm: number | undefined;
    zoom: number;
}

const initialState: CoverageAreaState = {
    latLong: { latitude: 45.2538, longitude: -69.4455},
    radiusInKm: 1,
    zoom: 7,
};

export const coverageAreaSlice = createSlice({
    name: 'coverageArea',
    initialState,
    reducers: {
        updateLatLong: (state, action: PayloadAction<LatLong>) => {
            state.latLong = action.payload;
        },
        updateRadiusInKm: (state, action: PayloadAction<number>) => {
            state.radiusInKm = action.payload;
        },
        updateZoom: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
    },
});

export const { updateLatLong, updateRadiusInKm, updateZoom } = coverageAreaSlice.actions;

// Selectors
export const selectLatLong = (state: RootState) => state.coverageArea.latLong;
export const selectRadiusInKm = (state: RootState) => state.coverageArea.radiusInKm;
export const selectZoom = (state: RootState) => state.coverageArea.zoom;
export const selectLatLongString = (state: RootState) => state.coverageArea.latLong.latitude + ", " + state.coverageArea.latLong.longitude;

export default coverageAreaSlice.reducer;
