import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { CoverageType, selectCoverageString, selectCoverageType, selectPaymentString, selectPayoutString, selectWorkString, updateCoverageType } from './coverageTypeSlice';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// eslint-disable-next-line no-empty-pattern
export const CoverageTypeStep = forwardRef<ReactNode>(({ }, ref) => {

    const coverageType = useAppSelector(selectCoverageType);
    const coverageString = useAppSelector(selectCoverageString);
    const paymentString = useAppSelector(selectPaymentString);
    const payoutString = useAppSelector(selectPayoutString);
    const workString = useAppSelector(selectWorkString);
    const dispatch = useAppDispatch();

    useImperativeHandle(ref, () => ({
        isValidated() {
            // return true;
            return coverageType !== undefined && coverageType !== null;
        }
    }));


    return (
        <div className="step step1">
            <br />
            <label className="col-md-12 control-label">
                <h1>Select Coverage Type</h1>
            </label>
            <h3>Select the coverage type that best fits your need.</h3>
            <br />
            <ToggleButtonGroup color="primary" value={coverageType} onChange={(event: React.MouseEvent<HTMLElement>, coverageType: CoverageType) => (dispatch(updateCoverageType(coverageType)))} exclusive={true}>
                <ToggleButton id="tbg-btn-1" value={"Daily Storm"}>
                    Daily Storm
                </ToggleButton>
                <ToggleButton id="tbg-btn-2" value={"Weekly Storm"}>
                    Weekly Storm
                </ToggleButton>
                <ToggleButton id="tbg-btn-3" value={"Seasonal Coverage"}>
                    Seasonal Coverage
                </ToggleButton>
            </ToggleButtonGroup>
            <br/>
            <br/>
            {coverageType && <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>What's covered?</strong></TableCell>
                            <TableCell align="left">{coverageString}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>How much do I get paid?</strong></TableCell>
                            <TableCell align="left">{paymentString}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Is there a deductible?</strong></TableCell>
                            <TableCell align="left">No</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>How soon do I get paid out?</strong></TableCell>
                            <TableCell align="left">{payoutString}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>What happens if I work anyway?</strong></TableCell>
                            <TableCell align="left">{workString}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>}
            <br />
            <br />
        </div >
    );
});