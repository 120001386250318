import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as EmailValidator from 'email-validator';
import { PhoneNumberUtil } from 'google-libphonenumber'
import { postcodeValidator } from 'postcode-validator';

export interface RegistrantInfoState {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    mailingAddress?: string,
    city?: string,
    state?: string,
    zip?: string,
    errors: RegistrantInfoErrors,
}

export interface RegistrantInfoErrors {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    mailingAddress?: string,
    city?: string,
    state?: string,
    zip?: string,
}

const initialState: RegistrantInfoState = {
    errors: {}
};

// export const validationSchema = yup.object({
//     firstName: yup.string().required(),
//     lastName: yup.string().required(),
//     email: yup.string().email().required(),
//     address: yup.string().required(),
//     city: yup.string().required(),
//     state: yup.string().required(),
//     zip: yup.number().min(5).max(5).required(),
// });

function requiredValidationFunction (propName: string, labelName: string, secondaryCheck?: {check: (value: string) => boolean, error: string}): ((state: any, action: PayloadAction<string>) => void) {
    return (state, action: PayloadAction<string>) => {
        if (action.payload && action.payload.length > 0) {
            if (secondaryCheck) {
                console.log("hello");
                if (!secondaryCheck.check(action.payload)) {
                    state[propName] = undefined;
                    state.errors[propName] = labelName + secondaryCheck.error;
                    return;
                }
            }
            state[propName] = action.payload;
            state.errors[propName] = undefined;
        } else {
            state[propName] = undefined;
            state.errors[propName] = labelName + " required.";
        }
    };
}

function validatePhoneNumber(phone: string): boolean {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
        return phoneUtil.isValidNumber(number);
    } catch (e) {
        return false;
    }
}

function validateZipcode(zipcode: string): boolean {
    return postcodeValidator(zipcode, 'US');
}



export const registrantInfoSlice = createSlice({
    name: 'coverageAmount',
    initialState,
    reducers: {
        updateFirstName: requiredValidationFunction("firstName", "First name"),
        updateLastName: requiredValidationFunction("lastName", "Last name"),
        updateEmail: requiredValidationFunction("email", "Email", {check: EmailValidator.validate, error: " is not a valid email."}),
        updatePhone: requiredValidationFunction("phone", "Phone number", {check: validatePhoneNumber, error: " is not a valid phone number."}),
        updateMailingAddress: requiredValidationFunction("mailingAddress", "Mailing address"),
        updateCity: requiredValidationFunction("city", "City"),
        updateState: requiredValidationFunction("state", "State"),
        updateZip: requiredValidationFunction("zip", "Zip code", {check: validateZipcode, error: " is not a valid zip code."}),
    },
});

export const { updateFirstName, updateLastName, updateEmail, updatePhone, updateMailingAddress, updateCity, updateState, updateZip } = registrantInfoSlice.actions;

// Selectors
export const selectRegistrantInfo = (state: RootState) => state.registrantInfo;
export const selectRegistrantInfoString = (state: RootState) => {
    const info = state.registrantInfo;
    return info.firstName + " " + info.lastName + "\n" +
           info.email + "\n" +
           info.phone + "\n" +
           info.mailingAddress + "\n" +
           info.city + ", " + info.state + " " + info.zip;
}


export default registrantInfoSlice.reducer;
