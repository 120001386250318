import React from 'react';
import StepZilla from 'react-stepzilla';
import './App.css';
import "react-stepzilla/src/css/main.css";
import "react-stepzilla/src/examples/redux/main.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAppDispatch } from './app/hooks';
import { updateActiveStep } from './features/steps/stepSlice';
import { InsuranceTypeStep } from './features/insurance-type/InsuranceType';
import { CoverageAreaStep } from './features/coverage-area/CoverageArea';
import { CoverageTypeStep } from './features/coverage-type/CoverageType';
import { CoverageAmountStep } from './features/coverage-amount/CoverageAmount';
import { RegistrantInfoStep } from './features/registrant-info/RegistrantInfo';
import { ConfirmationStep } from './features/confirmation/ConfirmationStep';

function App() {
  const dispatch = useAppDispatch();

  const steps =
    [
      { name: 'Business Type', component: <InsuranceTypeStep /> },
      { name: 'Coverage Area', component: <CoverageAreaStep /> },
      { name: 'Coverage Type', component: <CoverageTypeStep /> },
      { name: 'Coverage Amount', component: <CoverageAmountStep /> },
      { name: 'Registrant Info', component: <RegistrantInfoStep /> },
      { name: 'Confirmation', component: <ConfirmationStep /> },
    ]

  return (
    <div className='example'>
      <div className='step-progress'>
        <StepZilla
          steps={steps}
          preventEnterSubmission={true}
          // nextTextOnFinalActionStep="Confirm & Submit Booking"
          onStepChange={(step: number) => (dispatch(updateActiveStep(step)))}
          nextButtonText="Next Step"
          backButtonText="Previous Step"
          // showNavigation={false}
        />
      </div>
    </div>
  );
}

export default App;
