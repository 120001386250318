import TextField from "@mui/material/TextField/TextField";
import { ChangeEvent } from "react";
import { useAppDispatch } from "../../app/hooks";
import { RegistrantInfoErrors, RegistrantInfoState } from "./registrantInfoSlice";

export interface TextFieldValidationProps {
    name: string,
    label: string,
    onChangeFunc: (payload: string) => any,
    registrantInfo: RegistrantInfoState,
}



export function TextFieldValidationWrapper(props: TextFieldValidationProps){
    const dispatch = useAppDispatch();
    const errorVal = props.registrantInfo.errors[props.name as keyof RegistrantInfoErrors];

    return (
        <TextField 
                    type={props.name}
                    name={props.name}
                    label={props.label}
                    value={props.registrantInfo[props.name as keyof RegistrantInfoState]}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => (dispatch(props.onChangeFunc(event.currentTarget.value)))}
                    error ={props.name in props.registrantInfo.errors ? errorVal !== undefined : undefined}
                    helperText={props.name in props.registrantInfo.errors ? errorVal : undefined}
                    fullWidth
                    margin="normal"
                    />
    );
}