import React from 'react';
import { useAppSelector } from '../../app/hooks';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { selectInsuranceType } from '../insurance-type/insuranceTypeSlice';
import { selectLatLongString } from '../coverage-area/coverageAreaSlice';
import { selectCoverageType } from '../coverage-type/coverageTypeSlice';
import { selectCoverageAmount } from '../coverage-amount/coverageAmountSlice';
import { selectRegistrantInfoString } from '../registrant-info/registrantInfoSlice';

export function ConfirmationStep() {

    const insuranceType = useAppSelector(selectInsuranceType);
    const coverageAreaString = useAppSelector(selectLatLongString);
    const coverageType = useAppSelector(selectCoverageType);
    const coverageAmount = useAppSelector(selectCoverageAmount);
    const registrantInfoString = useAppSelector(selectRegistrantInfoString);

    return (
        <div className="step step2">
            <br/>
            <label className="col-md-12 control-label">
              <h1>Confirmation</h1>
            </label>
            <br/>
            <br/>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Business Type</strong></TableCell>
                            <TableCell align="left">{insuranceType}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Coverage Location</strong></TableCell>
                            <TableCell align="left">{coverageAreaString}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Coverage Type</strong></TableCell>
                            <TableCell align="left">{coverageType}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Coverage Amount</strong></TableCell>
                            <TableCell align="left">{coverageAmount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><strong>Registrant Info</strong></TableCell>
                            <TableCell align="left">{registrantInfoString}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <br />
        </div >
    );
}