import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { InsuranceType, selectInsuranceType, updateInsuranceType } from './insuranceTypeSlice';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

// eslint-disable-next-line no-empty-pattern
export const InsuranceTypeStep = forwardRef<ReactNode>(({ }, ref) => {

    const insuranceType = useAppSelector(selectInsuranceType);
    const dispatch = useAppDispatch();

    useImperativeHandle(ref, () => ({
        isValidated() {
            // return true;
            return insuranceType !== undefined && insuranceType !== null;
        }
    }));


    return (
        <div className="step step1">
            <br/> 
            <label className="col-md-12 control-label">
              <h1>Select Business Type</h1>
            </label>
            <h3>Select the business category that best describes your work.</h3>
            <br/>   
            <ToggleButtonGroup color="primary" value={insuranceType} onChange={(event: React.MouseEvent<HTMLElement>, insuranceType: InsuranceType) => (dispatch(updateInsuranceType(insuranceType)))} exclusive={true}>
                    <ToggleButton id="tbg-btn-1" value={"Commercial Fishing"}>
                        Commercial Fishing
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-2" value={"Aquaculture"}>
                        Aquaculture
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-3" value={"Offshore Servicing"}>
                        Offshore Servicing
                    </ToggleButton>
                    <ToggleButton id="tbg-btn-4" value={"Wind Farms"}>
                        Wind Farms
                    </ToggleButton>
            </ToggleButtonGroup>
        </div >
    );
});