import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type InsuranceType = "Commercial Fishing" | "Aquaculture" | "Offshore Servicing" | "Wind Farms" | undefined;
export interface InsuranceTypeState {
    insuranceTypeValue: InsuranceType;
}

const initialState: InsuranceTypeState = {
    insuranceTypeValue: undefined,
};

export const insuranceTypeSlice = createSlice({
    name: 'insuranceType',
    initialState,
    reducers: {
        updateInsuranceType: (state, action: PayloadAction<InsuranceType>) => {
            state.insuranceTypeValue = action.payload;
        },
    },
});

export const { updateInsuranceType } = insuranceTypeSlice.actions;

// Selectors
export const selectInsuranceType = (state: RootState) => state.insuranceType.insuranceTypeValue;

export default insuranceTypeSlice.reducer;
