import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const DAILY_STORM_VALUE: string = "Daily Storm";
const WEEKLY_STORM_VALUE: string = "Weekly Storm";
const SEASONAL_COVERAGE_VALUE: string = "Seasonal Coverage";
export type CoverageType = typeof DAILY_STORM_VALUE | typeof WEEKLY_STORM_VALUE | typeof SEASONAL_COVERAGE_VALUE | undefined;
export interface CoverageTypeState {
    coverageTypeValue: CoverageType;
}

const initialState: CoverageTypeState = {
    coverageTypeValue: undefined,
};

export const coverageTypeSlice = createSlice({
    name: 'coverageType',
    initialState,
    reducers: {
        updateCoverageType: (state, action: PayloadAction<CoverageType>) => {
            state.coverageTypeValue = action.payload;
        },
    },
});

export const { updateCoverageType } = coverageTypeSlice.actions;

// Selectors
export const selectCoverageType = (state: RootState) => state.coverageType.coverageTypeValue;

export const selectCoverageString = (state: RootState) =>  {
    const coverageType = state.coverageType.coverageTypeValue;
    if (coverageType) {
        let coverageString: string = "";
        if (coverageType === DAILY_STORM_VALUE || coverageType === WEEKLY_STORM_VALUE) {
            coverageString += "Bad weather that keeps you from working.\n";
        } else if (coverageType === SEASONAL_COVERAGE_VALUE) {
            coverageString += "Bad weather that keeps you from working more than 40% of the time from June 20 to December 31.\n";
        }

        coverageString += 
        `We define this as:
            Wind Speeds of: 
            Wave Height:\n`;

        if (coverageType === DAILY_STORM_VALUE) {
            coverageString += "That is registered in a single 24 hour period.";
        } else if (coverageType === WEEKLY_STORM_VALUE) {
            coverageString += "That is registered on three consecutive days.";
        } else if (coverageType === SEASONAL_COVERAGE_VALUE) {
            coverageString += "That is registered during the period from June 20 to December 31.";
        }
        return coverageString;
    } else {
        return undefined;
    }
}

export const selectPaymentPeriodString = (state: RootState) =>  {
    const coverageType = state.coverageType.coverageTypeValue;

    if (coverageType) {
        if (coverageType === DAILY_STORM_VALUE) {
            return "day";
        } else if (coverageType === WEEKLY_STORM_VALUE) {
            return "week";
        } else if (coverageType === SEASONAL_COVERAGE_VALUE) {
            return "season";
        }
    } else {
        return "period";
    }
}

export const selectPaymentString = (state: RootState) =>  {
    const coverageType = state.coverageType.coverageTypeValue;
    if (coverageType) {
        return "You pick the coverage amount - this should be the income you expect to lose if prevented from working for a " + selectPaymentPeriodString(state) + ".";
    } else {
        return undefined;
    }
}

export const selectPayoutString = (state: RootState) =>  {
    const coverageType = state.coverageType.coverageTypeValue;

    if (coverageType) {
        if (coverageType === DAILY_STORM_VALUE) {
            return "24 hours or less";
        } else if (coverageType === WEEKLY_STORM_VALUE) {
            return "24 hours or less after the third day";
        } else if (coverageType === SEASONAL_COVERAGE_VALUE) {
            return "24 hours or less";
        }
    } else {
        return undefined;
    }
}

export const selectWorkString = (state: RootState) =>  {
    const coverageType = state.coverageType.coverageTypeValue;

    if (coverageType) {
        if (coverageType === DAILY_STORM_VALUE || coverageType === WEEKLY_STORM_VALUE) {
            return "You forfeit the claim to this benefit; we track AIS data to confirm your vessel remains docked during the period.";
        } else if (coverageType === SEASONAL_COVERAGE_VALUE) {
            return "You still get paid.";
        }
    } else {
        return undefined;
    }
}

export default coverageTypeSlice.reducer;
