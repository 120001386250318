import Grid from '@mui/material/Grid';
import React, { forwardRef, useImperativeHandle } from 'react';
import { ReactNode } from 'react';
import { useAppSelector } from '../../app/hooks';
import { RegistrantInfoState, updateEmail, updatePhone, updateFirstName, updateLastName, updateMailingAddress, updateCity, updateState, updateZip, selectRegistrantInfo } from './registrantInfoSlice';
import { TextFieldValidationWrapper } from './TextFieldValidationWrapper';

// eslint-disable-next-line no-empty-pattern
export const RegistrantInfoStep = forwardRef<ReactNode>(({ }, ref) => {

    const registrantInfo: RegistrantInfoState = useAppSelector(selectRegistrantInfo);

    useImperativeHandle(ref, () => ({
        isValidated() {
            // return true;
            return registrantInfo.firstName !== undefined &&
                registrantInfo.lastName !== undefined &&
                registrantInfo.email !== undefined &&
                registrantInfo.phone !== undefined &&
                registrantInfo.mailingAddress !== undefined &&
                registrantInfo.city !== undefined &&
                registrantInfo.state !== undefined &&
                registrantInfo.zip !== undefined;
        }
    }));

    return (
        <div className="step step5">
            <br />
            <label className="col-md-12 control-label">
                <h1>Enter Registrant Info</h1>
            </label>
            <br />
            <br />
            <form style={{ width: "80%", display: "inline-block" }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldValidationWrapper
                            name="firstName" label="First Name"
                            onChangeFunc={updateFirstName}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldValidationWrapper
                            name="lastName" label="Last Name"
                            onChangeFunc={updateLastName}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldValidationWrapper
                            name="email" label="Email"
                            onChangeFunc={updateEmail}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextFieldValidationWrapper
                            name="phone" label="Phone Number"
                            onChangeFunc={updatePhone}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldValidationWrapper
                            name="mailingAddress" label="Mailing Address"
                            onChangeFunc={updateMailingAddress}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        <TextFieldValidationWrapper
                            name="city" label="City"
                            onChangeFunc={updateCity}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFieldValidationWrapper
                            name="state" label="State"
                            onChangeFunc={updateState}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFieldValidationWrapper
                            name="zip" label="Zip"
                            onChangeFunc={updateZip}
                            registrantInfo={registrantInfo}
                        />
                    </Grid>
                </Grid>
            </form>
            <br />
            <br />
            <br />
        </div >
    );
});