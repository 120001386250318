import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import stepsReducer from '../features/steps/stepSlice';
import insuranceTypeReducer from '../features/insurance-type/insuranceTypeSlice';
import coverageAreaReducer from  '../features/coverage-area/coverageAreaSlice';
import coverageTypeReducer from '../features/coverage-type/coverageTypeSlice';
import coverageAmountReducer  from '../features/coverage-amount/coverageAmountSlice';
import registrantInfoReducer  from '../features/registrant-info/registrantInfoSlice';

export const store = configureStore({
  reducer: {
    steps: stepsReducer,
    insuranceType: insuranceTypeReducer,
    coverageArea: coverageAreaReducer,
    coverageType: coverageTypeReducer,
    coverageAmount: coverageAmountReducer,
    registrantInfo: registrantInfoReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
