import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import MapPicker from 'react-google-map-picker'
import { selectLatLong, selectZoom, updateLatLong, updateZoom } from './coverageAreaSlice';

// eslint-disable-next-line no-empty-pattern
export const CoverageAreaStep = forwardRef<ReactNode>(({ }, ref) => {

    const latLong = useAppSelector(selectLatLong);
    const zoom = useAppSelector(selectZoom);
    const dispatch = useAppDispatch();

    useImperativeHandle(ref, () => ({
        isValidated() {
            // return true;
            return latLong && latLong.latitude && latLong.longitude;
        }
    }));

    return (
        <div className="step step2">
            <br/> 
            <label className="col-md-12 control-label">
                <h1>Select Coverage Area</h1>
            </label>
            <br/>
            <h3>Select the region of the Gulf of Maine that most impacts your business.</h3>
            <br/> 
            <MapPicker defaultLocation={{lat: latLong.latitude, lng: latLong.longitude}}
                zoom={zoom}
                style={{ height: '500px' }}
                onChangeLocation={(lat: number, lng: number) => dispatch(updateLatLong({latitude: lat, longitude: lng}))}
                onChangeZoom={(zoom: number) => dispatch(updateZoom(zoom))}
                apiKey='AIzaSyDhsS5UvxTaITt0Ms-_-A2_1zVC7R-zliI' />
            <br />
            <br />
        </div >
    );
});