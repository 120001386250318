import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import Slider from '@mui/material/Slider/Slider';
import { selectCoverageAmount, updateCoverageAmount } from './coverageAmountSlice';
import Input from '@mui/material/Input/Input';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import { selectPaymentPeriodString } from '../coverage-type/coverageTypeSlice';

// eslint-disable-next-line no-empty-pattern
export const CoverageAmountStep = forwardRef<ReactNode>(({ }, ref) => {

    const coverageAmount = useAppSelector(selectCoverageAmount);
    const paymentPeriodString = useAppSelector(selectPaymentPeriodString);
    const dispatch = useAppDispatch();
    const COVERAGE_AMOUNT_MIN = 100;
    const COVERAGE_AMOUNT_MAX = 1000;
    const COVERAGE_AMOUNT_STEP = 100;

    useImperativeHandle(ref, () => ({
        isValidated() {
            // return true;
            return coverageAmount && coverageAmount <= COVERAGE_AMOUNT_MAX && coverageAmount >= COVERAGE_AMOUNT_MIN;
        }
    }));

    return (
        <div className="step step4">
            <br/>
            <label className="col-md-12 control-label">
                <h1>Select Coverage Amount</h1>
            </label>
            <br/>
            <h3>Select the amount of coverage you are interested in - this is how much you income you could lose out on in a given {paymentPeriodString}, if the weather is especially harsh.</h3>
            <h3>We pay this amount directly to your bank account within 24-72 hours of a trigger event.</h3>
            <br/>
            <br/>
            <div style={{ width: "80%", display: "inline-block" }}>
                <Slider
                    aria-label="Amount in Dollars"
                    value={coverageAmount}
                    onChange={(event: Event, coverageAmount: number | number[]) => dispatch(updateCoverageAmount(coverageAmount))}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value: number) => `$${value}`}
                    step={COVERAGE_AMOUNT_STEP}
                    marks
                    min={COVERAGE_AMOUNT_MIN}
                    max={COVERAGE_AMOUNT_MAX}
                />
            </div>
            <br/>
            <Input
            value={coverageAmount}
            size="medium"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(updateCoverageAmount(event.target.value === '' ? 100 : Number(event.target.value)))}
            inputProps={{
              step: 100,
              min: COVERAGE_AMOUNT_MIN,
              max: COVERAGE_AMOUNT_MAX,
              type: 'number',
              'aria-labelledby': 'input-slider',
              
            }}
          />
          <br />
          <br />
          <h3>Claims are automatically filed and paid if the trigger conditions are recorded and compiled by the National Data Buoy Association on the closest buoy to your coverage area.</h3>
        </div >
    );
});