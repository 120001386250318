import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SliceState {
  say: string;
  activeStep: number;
}

const initialState: SliceState = {
  say: '',
  activeStep: 0,
};

export const stepSlice = createSlice({
  name: 'step',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    helloRedux: (state) => {
      state.say = 'Hello World Redux'
    },
    byeRedux: (state) => {
      state.say = ''
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateActiveStep: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.activeStep = action.payload;
      }
    },
  },
//   // The `extraReducers` field lets the slice handle actions defined elsewhere,
//   // including actions generated by createAsyncThunk or in other slices.
//   extraReducers: (builder) => {
//     builder
//       .addCase(incrementAsync.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(incrementAsync.fulfilled, (state, action) => {
//         state.status = 'idle';
//         state.value += action.payload;
//       });
//   },
});

export const { helloRedux, byeRedux, updateActiveStep } = stepSlice.actions;

// Selectors
export const selectSay = (state: RootState) => state.steps.say;
export const selectActiveStep = (state: RootState) => state.steps.activeStep;

// // The function below is called a selector and allows us to select a value from
// // the state. Selectors can also be defined inline where they're used instead of
// // in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default stepSlice.reducer;
