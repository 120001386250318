import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface CoverageAmountState {
    coverageAmountValue: number | undefined;
}

const initialState: CoverageAmountState = {
    coverageAmountValue: 100,
};

export const coverageAmountSlice = createSlice({
    name: 'coverageAmount',
    initialState,
    reducers: {
        updateCoverageAmount: (state, action: PayloadAction<number | number[]>) => {
            if (typeof action.payload  === 'number') {
                state.coverageAmountValue = action.payload;
            } else if (action.payload.length > 0) {
                state.coverageAmountValue = action.payload[0];
            } else {
                state.coverageAmountValue = 100;
            }
        },
    },
});

export const { updateCoverageAmount } = coverageAmountSlice.actions;

// Selectors
export const selectCoverageAmount = (state: RootState) => state.coverageAmount.coverageAmountValue;

export default coverageAmountSlice.reducer;
